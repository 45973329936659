import React from "react";
import styled from "styled-components";
import { Anchor } from "@src/components/elements/link";
import { ThemeProviderProps } from "../theme";

const FooterWrapper = styled.div`
  border-top: 1px solid
    ${(props: ThemeProviderProps) => props.theme.color.Selection};
  margin: 1rem auto;
  padding: 1rem 0;
  max-width: ${(props: ThemeProviderProps) => props.theme.dimensions.maxWidth};
  width: 100%;
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const FooterLink = styled(Anchor)`
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  margin: 0 0.4rem;

  &:hover {
    color: ${(props: ThemeProviderProps) => props.theme.color.Foreground};
  }
  @media (max-width: 700px) {
    width: 100%;
    display: block;
    margin: 0;
  }
`;

const FooterItem = styled.p`
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  margin: 0.4rem 0;
  padding: 0;
`;

const FooterStart = styled.div`
  justify-self: flex-start;
  flex: 1;
`;
const FooterEnd = styled.div`
  justify-self: flex-start;
`;

const Footer = () => (
  <FooterWrapper>
    <FooterStart>
      <FooterItem>
        <FooterLink href="https://owenkelly.com.au">OWEN KELLY</FooterLink>
      </FooterItem>
    </FooterStart>
    <FooterEnd>
      <FooterItem>
        <FooterLink href="https://ojkelly.dev/security.txt">
          security.txt
        </FooterLink>
        <FooterLink href="https://projects.owenkelly.com.au">
          Projects
        </FooterLink>
        <FooterLink href="https://www.github.com/ojkelly">GitHub</FooterLink>
        <FooterLink href="https://www.twitter.com/ojkelly">Twitter</FooterLink>
      </FooterItem>
    </FooterEnd>
  </FooterWrapper>
);

export default Footer;
